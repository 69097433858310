<template>
  <v-container>
    <h3 class="text-h4 mb-4 blue-grey--text text--darken-2">
      <v-icon color="primary" x-large left>mdi-account</v-icon>
      Perfis
    </h3>

    <ListaPerfis :key="compPerfisKey" />

    <NovoEditPerfis
      v-if="dialogNovoEditPerfil"
      :dialogNovoEditPerfil.sync="dialogNovoEditPerfil"
      :adicionar="adicionar"
      @fetch-perfis="reloadNovoEditPerfil"
    />
  </v-container>
</template>

<script>
export default {
  name: "Perfis",

  components: {
    NovoEditPerfis: () => import("./NovoEditPerfis"),
    ListaPerfis: () => import("./components/ListaPerfis"),
  },

  data() {
    return {
      compPerfisKey: 0,
      dialogNovoEditPerfil: false,
      adicionar: true,
    };
  },

  methods: {
    reloadNovoEditPerfil() {
      this.compPerfisKey += 1;
    },
  },
};
</script>

<style lang="scss"></style>
